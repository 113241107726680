export default {
  methods: {
    // 訂單狀態 參數轉字
    ostatusMixin(val) {
      if (val === 0) return '訂單成立'
      else if (val === 1) return '待處理'
      else if (val === 2) return '已完成'
      else if (val === 3) return '已取消'
    },
    // 付款狀態 參數轉字
    pstatusMixin(val) {
      if (val === -1) return '交易異常'
      else if (val === 0) return '未付款'
      else if (val === 1) return '貨到付款'
      else if (val === 2) return '已付款'
      else if (val === 3) return '已退款'
    },
    // 出貨狀態 參數轉字
    lstatusMixin(val) {
      if (val === 0) return '未出貨'
      else if (val === 1) return '部分出貨'
      else if (val === 2) return '已出貨'
      else if (val === 3) return '未取貨'
      else if (val === 4) return '已取貨'
      else if (val === 5) return '申請退貨'
      else if (val === 6) return '已退貨'
    },
     // 付款方式第三方 參數轉字
     pthirdMixin(val) {
      if (val === 0) return ''
      else if (val === 1) return '綠界'
      else if (val === 2) return '藍新'
    },
    // 付款方式 參數轉字
    ptermsMixin(val) {
      if (val === 0) return '無'
      else if (val === 1) return '線上刷卡'
      else if (val === 2) return 'ATM轉帳'
      else if (val === 3) return 'WebATM'
      else if (val === 4) return '超商代碼'
      else if (val === 5) return '超商條碼'
      else if (val === 6) return '貨到付款'
      else if (val === 7) return '現金'
      else if (val === 8) return '匯款'
      else if (val === 9) return '預購'
      else if (val === 10) return '線下信用卡'
    },
    // 送貨方式第三方 參數轉字
    lthirdMixin(val) {
      if (val === 0) return ''
      else if (val === 1) return '綠界'
      else if (val === 2) return 'ezShip'
    },
    // 送貨方式 參數轉字
    logisticsMixin(val) {
      if (val === 0) return '無'
      else if (val === 1) return '7-11'
      else if (val === 2) return '全家'
      else if (val === 3) return '萊爾富'
      else if (val === 4) return 'OK'
      else if (val === 5) return '黑貓宅配'
      else if (val === 6) return '賣家宅配'
      else if (val === 7) return '海外配送'
    },
    // 溫層 參數轉字
    tempMixin(val) {
      if (val === 0) return ''
      else if (val === 1) return '常溫'
      else if (val === 2) return '冷藏'
      else if (val === 3) return '冷凍'
    },
    // 規格 參數轉字
    specMixin(val) {
      if (val === 0) return ''
      else if (val === 1) return '60cm'
      else if (val === 2) return '90cm'
      else if (val === 3) return '120cm'
      else if (val === 4) return '150cm'
    },
    // 送貨時間 參數轉字
    ltimeMixin(val) {
      if (val === 0) return '不分時段'
      else if (val === 1) return '中午前'
      else if (val === 2) return '12時=>17時'
      else if (val === 3) return '17時=>20時'
    },
    // 發票 參數轉字
    intype1Mixin(val) {
      if (val === 0) return '紙本發票'
      else if (val === 1) return '捐贈發票'
      else if (val === 2) return '電子發票'
    },
    // 發票 參數轉字
    intype2Mixin(val) {
      if (val === 0) return ''
      else if (val === 1) return ''
      else if (val === 2) return '2聯式'
      else if (val === 3) return '3聯式'
    },
  },
}
