<template>
  <div class="space-y-5">
    <div class="flex items-center justify-between flex-row-reverse lg:flex-row">
      <h1 class="page_title">{{ $route.meta.title }}</h1>

      <router-link v-if="is_API_loaded" :to="{ name: 'create_order' }">
        <vs-button size="large"><span class="font-medium">創建訂單</span></vs-button>
      </router-link>
      <!-- 骨架屏 -->
      <skeleton v-else width="82px" />
    </div>

    <main v-if="is_API_loaded" class="bg-white rounded-2xl w-full pt-4">
      <section class="flex px-4 mb-2 space-x-3">
        <vs-button @click="open_drawer" class="flex-shrink-0" border color="success">進階篩選</vs-button>
        <vs-input
          class="w-full"
          v-model="uname"
          @blur="searchAll"
          @keypress.enter="searchAll"
          placeholder="以訂單編號、姓名、電話或電子郵件來搜尋"
        />
      </section>

      <!-- filter 區塊 -->
      <section class="filter_section">
        <div class="filter_box" v-for="(box, str) in filterBoxAry" :key="str" v-if="box.status !== ''">
          <p>{{ box.label }}{{ box.status }}</p>
          <button @click="filterBoxCancel(box, str)" class="mb-1">
            <svg-icon icon-class="cancel" className="gray-text-light xs"></svg-icon>
          </button>
        </div>
      </section>

      <section class="text-sm overflow-x-auto">
        <div class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-min xl:w-full">
          <vs-checkbox v-if="indeterminate" indeterminate v-model="indeterminate" class="checkbox_section" @click="indeterminateHandleClick"
            ><span class="text-xs">已選取 {{ selected.length }} 筆</span></vs-checkbox
          >

          <vs-checkbox v-else class="checkbox_section" v-model="selectAll" @click="checkAllHandleClick">
            <span v-if="selectAll" class="text-xs">已選取 {{ selected.length }} 筆</span>
            <span v-else class="text-xs">共 {{ orderList.length }} 筆</span>
          </vs-checkbox>

          <transition name="fade">
            <div v-if="indeterminate || selectAll" class="title_section" key="1">
              <!-- <div class="w-max border border-gray-border rounded-xl grid grid-flow-col h-8 divide-x divide-gray-border overflow-hidden">
                <button @click="toPackingSlip" class="px-4 hover:bg-gray-background font-medium">列印揀貨單</button>
              </div> -->
            </div>
            <div v-else class="title_section" key="2">
              <span class="title">訂單編號</span>
              <span class="title2">總計</span>
              <span class="title2">顧客姓名</span>
              <span class="title2">訂單狀態</span>
              <span class="title2">付款狀態</span>
              <span class="title2">出貨狀態</span>
              <span class="title2">訂單日期</span>
            </div>
          </transition>
        </div>

        <div class="flex flex-col divide-y divide-gray-background text-gray-text_light w-min xl:w-full">
          <router-link
            v-for="item in orderList"
            :key="item.smono"
            :to="{
              name: 'edit-order',
              params: {
                id: item.smono,
              },
            }"
            class="flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative group"
          >
            <vs-checkbox class="checkbox_section" @click.stop="checkBoxHandleClick" v-model="selected" :val="item.smono"></vs-checkbox>
            <div class="title_section">
              <div class="title relative">
                {{ item.ordernum }}
                <div @click.stop="" v-if="item.ctype === 1" class="absolute top-1/2 transform -translate-y-1/2 -left-14">
                  <vs-tooltip>
                    <svg-icon icon-class="order_box" className="gray-border_stroke sm"></svg-icon>
                    <template #tooltip>此為您於後台手動創建的訂單</template>
                  </vs-tooltip>
                </div>
                <div @click.stop="" v-else-if="item.ctype === 2" class="absolute top-1/2 transform -translate-y-1/2 -left-14">
                  <vs-tooltip>
                    <img src="@/assets/images/line.png" width="21px"/>
                    <template #tooltip>此為LINE BOT創建的訂單</template>
                  </vs-tooltip>
                </div>
              </div>
              <!-- 總計 -->
              <p class="title2">NT${{ item.opays | currency }}</p>
              <!-- 顧客姓名 -->
              <div class="title2 flex items-center space-x-2">
                <p>{{ item.uname }}</p>
                <vs-tooltip v-if="item.mtype">
                  <svg-icon icon-class="tick" className="success_stroke xxs"></svg-icon>
                  <template #tooltip>此顧客為您的會員</template>
                </vs-tooltip>
              </div>
              <!-- 訂單狀態 -->
              <div class="title2">
                <span
                  class="rounded-full px-3 py-1"
                  :class="{
                    'bg-success text-white': item.ostatus === 0,
                    'bg-danger text-white': item.ostatus === 1,
                    'bg-gray-background text-gray-text_light': item.ostatus === 2 || item.ostatus === 3,
                  }"
                >
                  {{ ostatusMixin(item.ostatus) }}
                </span>
              </div>
              <!-- 付款狀態 -->
              <div class="title2">
                <span
                  class="rounded-full px-3 py-1"
                  :class="{
                    'bg-warning text-white': item.pstatus === -1,
                    'bg-danger text-white': item.pstatus === 0,
                    'bg-success  text-white': item.pstatus === 1 || item.pstatus === 2,
                    'bg-gray-background text-gray-text_light': item.pstatus === 3,
                  }"
                >
                  {{ pstatusMixin(item.pstatus) }}
                </span>
              </div>
              <!-- 出貨狀態 -->
              <div class="title2">
                <span
                  class="rounded-full px-3 py-1"
                  :class="{
                    'bg-danger text-white': item.lstatus === 0,
                    'bg-success text-white': item.lstatus === 2 || item.lstatus === 4,
                    'bg-gray-background text-gray-text_light': item.lstatus === 3 || item.lstatus === 6,
                    'bg-warning text-white': item.lstatus === 1 || item.lstatus === 5,
                  }"
                >
                  {{ lstatusMixin(item.lstatus) }}
                </span>
              </div>

              <!-- 訂單日期 -->
              <div class="title2">
                <p>{{ item.odate.split(' ')[0] }}</p>
                <p>{{ item.odate.split(' ')[1] }}</p>
              </div>
              <!-- 訂單金額 -->
            </div>
          </router-link>
        </div>
      </section>
    </main>

    <!-- 骨架屏區塊 -->
    <main v-else class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <skeleton />
        <skeleton class="sm:flex-shrink-0" :width="200" />
      </section>
      <section class="text-sm overflow-x-auto">
        <div v-for="(item, index) in 3" :key="index" class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-full">
          <div class="checkbox_section">
            <skeleton :width="23" :height="23" />
          </div>
          <skeleton width="100%" height="23px" />
        </div>
      </section>
    </main>

    <!-- pagination -->
    <div v-if="pagination.tpages > 1" class="flex flex-col items-center space-y-2">
      <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.tpages" />
      <span class="text-xs text-gray-400">總共 {{ pagination.pcounts }} 筆</span>
    </div>

    <!-- 刪除部落格 dialog -->
    <!-- <vs-dialog overflow-hidden blur v-model="deletePosts_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除 {{ selected.length }} 筆部落格？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的部落格無法復原，在此部落格底下的文章也會一併刪除，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deletePosts_dialog = false">取消</vs-button>
          <vs-button @click="bathDelete" size="large" transparent color="danger" ref="deletePostBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog> -->
    <!-- 側邊選單 -->
    <ValidationObserver v-slot="{ invalid }">
      <drawer>
        <template #header>
          <h1>進階篩選</h1>
        </template>

        <section class="space-y-4">
          <div>
            <h4 class="h4 mb-1">訂單狀態</h4>
            <vs-select v-model="ostatus">
              <vs-option v-for="item in ostatusAry" :key="item.value" :label="item.label" :value="item.value">{{ item.label }}</vs-option>
            </vs-select>
          </div>
          <div>
            <h4 class="h4 mb-1">付款狀態</h4>
            <vs-select v-model="pstatus">
              <vs-option v-for="item in pstatusAry" :key="item.value" :label="item.label" :value="item.value">{{ item.label }}</vs-option>
            </vs-select>
          </div>
          <div>
            <h4 class="h4 mb-1">出貨狀態</h4>
            <vs-select v-model="lstatus">
              <vs-option v-for="item in lstatusAry" :key="item.value" :label="item.label" :value="item.value">{{ item.label }}</vs-option>
            </vs-select>
          </div>
          <div>
            <h4 class="h4 mb-1">訂單日期</h4>
            <div class="flex flex-col space-y-2">
              <div class="flex items-center space-x-3">
                <span>起</span>
                <vs-input class="w-full" type="date" :max="_today" v-model="odate1" @change="odate2 = odate1"></vs-input>
              </div>
              <div class="flex items-center space-x-3">
                <span>訖</span>
                <vs-input class="w-full" type="date" :max="_today" :min="odate1" v-model="odate2" :disabled="odate1 === ''"></vs-input>
              </div>
            </div>
          </div>
          <div>
            <h4 class="h4 mb-1">付款方式</h4>
            <vs-select v-model="pterms">
              <vs-option v-for="item in ptermsAry" :key="item.value" :label="item.label" :value="item.value">{{ item.label }}</vs-option>
            </vs-select>
          </div>
          <div>
            <h4 class="h4 mb-1">發票狀態</h4>
            <vs-select v-model="istatus">
              <vs-option v-for="item in istatusAry" :key="item.value" :label="item.label" :value="item.value">{{ item.label }}</vs-option>
            </vs-select>
          </div>
        </section>

        <template #footer>
          <div class="flex items-center justify-between w-full">
            <div>
              <vs-button size="large" transparent class="font-medium" @click="filterReset" color="danger"> 清除全部 </vs-button>
            </div>
            <div class="flex items-center justify-center space-x-4">
              <vs-button size="large" transparent class="font-medium" @click="close_drawer"> 取消 </vs-button>
              <vs-button @click="filterConfirm(), close_drawer()" size="large" class="font-medium" :disabled="invalid" ref="filterBtn">
                <span>確定</span>
              </vs-button>
            </div>
          </div>
        </template>
      </drawer>
    </ValidationObserver>
  </div>
</template>

<script>
import drawer from '@/components/drawer.vue'
import orderStatus from '@/assets/mixins/orderStatus.js'
export default {
  name: 'orders',
  mixins: [orderStatus],
  components: {
    drawer,
  },
  data() {
    return {
      is_API_loaded: false,
      currentPage: 1, // 當前頁數
      orderList: [],
      pagination: [], // 頁數資訊
      selected: [],
      selectAll: false,
      indeterminate: false,
      defaultQuery: {}, // 預設$route.query
      ostatus: -99, // 訂單狀態
      ostatusAry: [
        {
          label: '全部',
          value: -99,
        },
        {
          label: '訂單成立',
          value: 0,
        },
        {
          label: '待處理',
          value: 1,
        },
        {
          label: '已完成',
          value: 2,
        },
        {
          label: '已取消',
          value: 3,
        },
      ],
      pstatus: -99, // 付款狀態
      pstatusAry: [
        {
          label: '全部',
          value: -99,
        },
        {
          label: '交易異常',
          value: -1,
        },
        {
          label: '未付款',
          value: 0,
        },
        {
          label: '貨到付款',
          value: 1,
        },
        {
          label: '已付款',
          value: 2,
        },
        {
          label: '已退款',
          value: 3,
        },
      ],
      lstatus: -99, // 出貨狀態
      lstatusAry: [
        {
          label: '全部',
          value: -99,
        },
        {
          label: '未出貨',
          value: 0,
        },
        {
          label: '部分出貨',
          value: 1,
        },
        {
          label: '已出貨',
          value: 2,
        },
        {
          label: '已取貨',
          value: 3,
        },
        {
          label: '申請退貨',
          value: 4,
        },
        {
          label: '已退貨',
          value: 5,
        },
      ],
      istatus: -99, // 發票狀態
      istatusAry: [
        {
          label: '全部',
          value: -99,
        },
        {
          label: '未寄送',
          value: 0,
        },
        {
          label: '已寄送',
          value: 1,
        },
      ],
      pterms: -99, // 付款方式
      ptermsAry: [
        {
          label: '全部',
          value: -99,
        },
        {
          label: '無',
          value: 0,
        },
        {
          label: '線上刷卡',
          value: 1,
        },
        {
          label: 'ATM轉帳',
          value: 2,
        },
        {
          label: 'WebATM',
          value: 3,
        },
        {
          label: '超商代碼',
          value: 4,
        },
        {
          label: '超商條碼',
          value: 5,
        },
        {
          label: '貨到付款 / 取貨付款',
          value: 6,
        },
        {
          label: '現金',
          value: 7,
        },
        {
          label: '匯款',
          value: 8,
        },
        {
          label: '預購',
          value: 9,
        },
      ],
      odate1: '', // 訂單日期(起)
      odate2: '', // 訂單日期(訖)
      uname: '',
      unameCopy: '',
      filterBoxAry: {
        ostatus: {
          label: '訂單狀態: ',
          status: '',
        },
        pstatus: {
          label: '付款狀態: ',
          status: '',
        },
        lstatus: {
          label: '出貨狀態: ',
          status: '',
        },
        odate: {
          label: '',
          status: '',
        },
        pterms: {
          label: '付款方式: ',
          status: '',
        },
        istatus: {
          label: '發票狀態: ',
          status: '',
        },
      },
    }
  },
  computed: {
    // 把多選的checkbox陣列轉字串
    _selectedToStr() {
      return this.selected.toString()
    },
    // 今天日期
    _today() {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1
      const day = today.getDate()

      function plusZero(value) {
        if (value < 10) return '0' + value
        else return value
      }

      return `${year}-${plusZero(month)}-${plusZero(day)}`
    },
  },
  watch: {
    '$route.query'() {
      this.filterBoxList()
    },
  },
  created() {
    let query = this.$route.query
    this.defaultQuery = query

    this.ostatus = parseInt(query.ostatus) || -99
    this.pstatus = parseInt(query.pstatus) || -99
    this.lstatus = parseInt(query.lstatus) || -99
    this.istatus = parseInt(query.istatus) || -99
    this.pterms = parseInt(query.pterms) || -99
    this.odate1 = query.odate1 || ''
    this.odate2 = query.odate2 || ''
    this.uname = query.uname || ''
    this.unameCopy = query.uname || ''
    this.currentPage = parseInt(query.page) || 1
    this.filterBoxList()
    this.getOrderList()
  },
  methods: {
    // 改變頁籤時觸發
    changePagination() {
      this.selected = []
      this.selectAll = false
      this.indeterminate = false
      this.getOrderList([['page', this.currentPage]])
    },
    // 篩選標題時觸發
    searchAll(e) {
      // 防止沒輸入文字也可以搜尋

      if (this.uname === this.unameCopy) return
      this.getOrderList([
        ['page', 1],
        ['uname', this.uname],
      ])

      this.currentPage = 1
      this.unameCopy = this.uname
    },
    checkBoxHandleClick(e) {
      setTimeout(() => {
        if (this.selected.length > 0 && this.selected.length < this.orderList.length) {
          // 勾選卻沒有全選時
          this.indeterminate = true
        } else if (this.selected.length === this.orderList.length) {
          // 全選時
          this.indeterminate = false
          this.$nextTick(() => {
            this.selectAll = true
          })
        } else {
          // 沒選時
          this.indeterminate = false
          this.selectAll = false
        }
      }, 0)
    },
    checkAllHandleClick(e, status) {
      let value
      e ? (value = e.target.checked) : (value = status)
      if (value) {
        for (let i = 0; i < this.orderList.length; i++) {
          this.selected.push(this.orderList[i].smono)
        }
      } else {
        this.selected = []
      }
    },
    indeterminateHandleClick(e) {
      let value = e.target.checked
      this.checkAllHandleClick(null, value)
    },
    // 3.3.1.1 讀取訂單列表
    getOrderList(arr) {
      // 深拷貝
      let routeQuery = JSON.parse(JSON.stringify(this.$route.query))

      // 新增或覆蓋進routeQuery物件
      if (arr && arr.length > 0) {
        arr.forEach((item) => {
          routeQuery[item[0]] = item[1]
        })
      }

      // 迴圈，若物件中有空值就刪除
      for (let key in routeQuery) {
        // 若值為空，刪除。
        if (routeQuery[key] === '' || routeQuery[key] === -99) {
          delete routeQuery[key]
        }
      }

      // 判斷物件是否一樣
      function objCompare(obj1, obj2) {
        const obj1_keys = Object.keys(obj1)
        const obj2_keys = Object.keys(obj2)
        if (obj1_keys.length !== obj2_keys.length) {
          return false
        }
        for (let key of obj1_keys) {
          if (obj1[key] !== obj2[key]) {
            return false
          }
        }
        return true
      }

      if (!objCompare(routeQuery, this.defaultQuery)) {
        // 不一樣，重整query
        this.$router.replace({ query: routeQuery })
        this.defaultQuery = routeQuery
      }

      this.$axios({
        url: 'front/store/morder/getMOrderList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: routeQuery.page ? routeQuery.page : 1,
          ostatus: routeQuery.ostatus !== undefined ? routeQuery.ostatus : -99, // 訂單狀態
          pstatus: routeQuery.pstatus !== undefined ? routeQuery.pstatus : -99, // 付款狀態
          lstatus: routeQuery.lstatus !== undefined ? routeQuery.lstatus : -99, // 出貨狀態
          istatus: routeQuery.istatus !== undefined ? routeQuery.istatus : -99, // 發票狀態
          pterms: routeQuery.pterms !== undefined ? routeQuery.pterms : -99, // 付款方式
          odate1: routeQuery.odate1 ? routeQuery.odate1 : '', // 訂單日期 起
          odate2: routeQuery.odate2 ? routeQuery.odate2 : '', // 訂單日期 訖
          uname: routeQuery.uname ? routeQuery.uname : '', // searchAll
          ordernum: routeQuery.uname ? routeQuery.uname : '', // 訂單編號
          email: routeQuery.uname ? routeQuery.uname : '', // 購買人名字信箱
          mp: routeQuery.uname ? routeQuery.uname : '', // 購買人電話號碼
        },
      }).then((res) => {
        if (res.data.Success) {
          this.orderList = res.data.Data
          this.pagination = res.data.Page
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 開啟drawer選單
    open_drawer() {
      this.$store.commit('DRAWER_TOGGLER', {
        name: 'DRAWER_TOGGLER',
      })
      this.$store.commit('OVERLAY_TOGGLER', true)
    },
    // 關閉drawer選單
    close_drawer() {
      // this.$store.commit('DRAWER_TOGGLER', {
      //   name: '',
      // })
      this.$store.dispatch('closeOverlay')
    },
    // 進階篩選確定
    filterConfirm() {
      this.getOrderList([
        ['ostatus', this.ostatus],
        ['pstatus', this.pstatus],
        ['lstatus', this.lstatus],
        ['istatus', this.istatus],
        ['pterms', this.pterms],
        ['odate1', this.odate1],
        ['odate2', this.odate2],
        ['page', 1],
      ])
    },
    // query資料改動時，頁面上呈現篩選狀態
    filterBoxList() {
      let query = this.$route.query

      function findIndex(ary, status) {
        return ary.findIndex((e) => e.value === parseInt(status))
      }

      // 訂單狀態
      if (query.ostatus >= 0) {
        let index = findIndex(this.ostatusAry, query.ostatus)
        this.filterBoxAry.ostatus.status = this.ostatusAry[index].label
      } else {
        this.filterBoxAry.ostatus.status = ''
      }

      // 付款狀態
      if (query.pstatus >= -1) {
        let index = findIndex(this.pstatusAry, query.pstatus)
        this.filterBoxAry.pstatus.status = this.pstatusAry[index].label
      } else {
        this.filterBoxAry.pstatus.status = ''
      }

      // 出貨狀態
      if (query.lstatus >= 0) {
        let index = findIndex(this.lstatusAry, query.lstatus)
        this.filterBoxAry.lstatus.status = this.lstatusAry[index].label
      } else {
        this.filterBoxAry.lstatus.status = ''
      }

      // 訂單日期 起
      if (query.odate1) {
        this.filterBoxAry.odate.status = this.odate1
      } else {
        this.filterBoxAry.odate.status = ''
      }

      // 訂單日期 訖
      if (query.odate2) {
        this.filterBoxAry.odate.status += ` ~ ${this.odate2}`
      } else {
        this.filterBoxAry.odate.status = ''
      }

      // 付款方式
      if (query.pterms >= 0) {
        let index = findIndex(this.ptermsAry, query.pterms)
        this.filterBoxAry.pterms.status = this.ptermsAry[index].label
      } else {
        this.filterBoxAry.pterms.status = ''
      }

      // 發票狀態
      if (query.istatus >= 0) {
        let index = findIndex(this.istatusAry, query.istatus)
        this.filterBoxAry.istatus.status = this.istatusAry[index].label
      } else {
        this.filterBoxAry.istatus.status = ''
      }
    },
    // 篩選顯上區塊，按叉叉後
    filterBoxCancel(box, str) {
      // 取消filter區塊按鈕顯示
      this.filterBoxAry[str].status = ''

      // 若是一些狀態，預設-99
      if (str === 'ostatus' || str === 'pstatus' || str === 'lstatus' || str === 'istatus' || str === 'pterms') {
        this[str] = -99
      } else if (str === 'odate') {
        // 若是日期
        this.odate1 = ''
        this.odate2 = ''
      } else {
        this[str] = ''
      }
      this.filterConfirm()
    },
    // 清除全部篩選
    filterReset() {
      this.ostatus = -99
      this.pstatus = -99
      this.lstatus = -99
      this.istatus = -99
      this.pterms = -99
      this.odate1 = ''
      this.odate2 = ''
      this.filterConfirm()
      this.close_drawer()
    },
    // 開啟揀貨單
    toPackingSlip() {
      let routeData = this.$router.resolve({ name: 'packing_slip', query: { order: this._selectedToStr } })
      window.open(routeData.href, '_blank')
    },
  },
  // 如果drawer是開的，離開路由時把它關上。
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.drawer) {
      // this.close_drawer()
      this.$store.dispatch('closeOverlay')
    }
    next()
  },
}
</script>
<style lang="scss" scoped>
.checkbox_section {
  @apply w-28 flex-shrink-0;
}

.title_section {
  @apply flex items-center w-min xl:w-full;
  .title {
    @apply w-48 sm:flex-grow;
  }

  .title2 {
    @apply w-36;
  }
}

.filter_section {
  @apply px-4 flex flex-wrap;

  .filter_box {
    @apply bg-gray-200 rounded-xl h-10 px-3 space-x-1
    flex items-center justify-center mr-2 mb-2;
  }
}
</style>
